.list-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color-base);
  margin-left: 10px;
  min-width: 340px;
}

.postText {
  height: 50vh;
  max-height: 100vh;
  overflow: auto;
}

.postHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag {
  padding: 10px;
  margin-top: 10px;
}

.shareButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shareButtonContainer > button {
  margin-right: 5px;
}

.shareButtonContainerSmallBreakpoint {
  display: none;
}

@media screen and (max-width: 768px) {
  .shareButtonContainer {
    display: none;
  }

  .shareButtonContainerSmallBreakpoint {
    display: block;
  }
}
