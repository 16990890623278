@import '../../../../Style/color.scss';

.postContainer {
  width: 80%;
  margin: auto;
  padding: 40px 0;
  .title {
    color: $textColorDarkTheme;
    font-weight: bold;
    font-size: 2.4rem;
  }
  .sectionInput {
    width: 50%;
    margin-top: 20px;
  }
  .searchInput {
    outline: none;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: $iconColorDarkTheme;
    input {
      margin-left: 10px;
      background-color: transparent;
      color: $textColorDarkTheme;
    }
    &:hover {
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: $textColorDarkTheme;
    }
  }
  .formContainer {
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px;
    .mediaContainer {
      background-color: #000;
      height: 200px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;
    }
    .btnSubmit {
      border-radius: 20px;
      margin-top: 20px;
    }
  }
  .rulesContainer {
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    .title {
      font-size: 2rem;
    }
    .text {
      color: $textColorDarkTheme;
    }
  }
}

@media screen and (max-width: 768px) {
  .postContainer {
    width: 95%;
    padding: 40px 5px 40px 5px;
  }
}
