.homeContent {
  width: 68%;
}

.rightHandSideContent {
  position: fixed;
  right: 3px;
  width: calc(100vw - 77%);
  padding: 0 10px 0 20px;
}

.container {
  display: flex;
}

@media screen and (max-width: 992px) {
  .homeContent {
    width: 95%;
  }

  .rightHandSideContent {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .homeContent {
    width: 100%;
  }
}
