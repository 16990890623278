.userProfile {
  width: 75%;
  margin-bottom: 15px;
}

.about {
  font-size: 1.4rem;
}

.cardDescription {
  white-space: break-spaces;
}

.userMeta {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .userProfile {
    margin: auto;
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .userProfile {
    border: none;
    width: 100%;
  }
}
