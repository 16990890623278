.ant-modal-content {
  border-radius: 16px;
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-menu-light .ant-menu-submenu-title {
  color: #808080;
}
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #808080;
  background-color: #313131;
}

.ant-menu-item:hover,
.ant-menu-item a:hover {
  color: #fff;
  background-color: #313131;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #fff;
  background-color: transparent;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-divider-horizontal {
  margin: 15px 0;
}
