@import '../../Style/color.scss';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 !important;
  z-index: 2;

  .navbarContainer {
    height: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px 20px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .itemContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .iconCollapse {
        color: $textColorDarkTheme;
        background-color: transparent;
        border: none;
        margin-right: 10px;

        &:hover {
          background-color: $iconHoverColorDarkTheme;
        }

        @media screen and (max-width: 500px) {
          & {
            margin: 0 3px 0 3px;
          }
        }
      }
      .logo {
        color: $textColorDarkTheme;
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 0;
        cursor: pointer;
      }
      .avatar {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        min-width: 0;
        margin: 0 20px;

        @media screen and (max-width: 500px) {
          & {
            margin: 0 3px 0 3px;
          }
        }
      }
      .text {
        color: $textColorDarkTheme;
        font-size: 1.6rem;
        max-width: 20vw;
        white-space: nowrap;

        @media screen and (max-width: 500px) {
          & {
            display: none;
          }
        }
      }
      .btnPost {
        border-radius: 20px;
        font-weight: bold;

        @media screen and (max-width: 500px) {
          & {
            margin: 0 5px 0 5px;

            span:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}

.navbarDropdown {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 5px;
  width: 220px;
  background-color: $backGroundColorDarkTheme;
  transition: all 2s ease-in;
  .btnDropdown {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    text-align: left;
    background-color: transparent;
    color: $textColorDarkTheme;
    border: none;
    outline: none;
    padding: 10px 25px;
    cursor: pointer;
    &:hover {
      color: $textColorDarkTheme;
      background-color: $iconHoverColorDarkTheme;
    }
  }
}
