@import '../ShareButton.module.scss';

.icon {
  font-size: 1.8rem;
}

.modalTitle {
  font-weight: bolder;
  text-align: center;
  width: 100%;
}
