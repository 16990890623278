.media {
  background-color: var(--contrast-background-color);
  border-radius: var(--border-radius-base);
  object-fit: contain;
}

.media-size {
  min-width: 52vw;
  width: 100%;
  height: 80vh !important;
}

.nsfwMedia {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #333333;
}

@media screen and (max-width: 992px) {
  .media-size {
    min-width: 90vw;
  }
}

@media screen and (max-width: 768px) {
  .media-size {
    min-width: 92vw;
  }
}

@media screen and (min-height: 705px) {
  .media-size {
    height: 45vh !important;
  }
}
