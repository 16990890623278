.commentAction {
  font-size: 1.2rem !important;
}

.actionBtn {
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-container {
  margin-left: 5px;
}
