.chatBoxQueue {
  position: fixed;
  bottom: 3px;
  right: 50px;
}

.chatBoxContainer {
  width: 370px;
}

@media screen and (max-width: 768px) {
  .chatBoxQueue {
    right: 3px;
  }
}
