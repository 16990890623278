@import '../../../../Style/color.scss';

.searchContainer {
  padding: 10px;
  background-color: $backGroundColorDarkTheme;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  .searchInput {
    background-color: $iconHoverColorDarkTheme;
    color: $iconColorDarkTheme;
    width: 300px;
    input {
      margin-left: 10px;
      color: $iconColorDarkTheme;
    }
    &:hover {
      background-color: $iconHoverColorDarkTheme;
      color: $iconColorDarkTheme;
    }
  }
}

.iconCollapse {
  color: $textColorDarkTheme;
  background-color: transparent;
  border: none;
  margin-right: 5px;

  &:hover {
    background-color: $iconHoverColorDarkTheme;
  }

  @media screen and (max-width: 500px) {
    & {
      margin: 0 3px 0 3px;
    }
  }
}
