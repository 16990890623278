@keyframes highlightComment {
  from {
    background-color: var(--border-color-base);
  }
  to {
    background-color: transparent;
  }
}

.highlight-comment {
  background-color: var(--border-color-base);
  border-radius: var(--border-radius-base);
  animation-name: highlightComment;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
