.markdownViewContainer {
  max-height: 70vh;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 15px;
  margin-top: 5px;

  p {
    margin: 0;
  }
}
