.formContainer {
  .title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 24px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
  .navigateText {
    display: block;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
  }
}
