$backGroundColorDarkTheme: #141414;
$backGroundColorLightTheme: #fff;
$textColorDarkTheme: #fff;
$textColorLightTheme: #000000;
$iconColorDarkTheme: #999999;
$iconSectionColorDarkTheme: #2b2b2b;
$iconColorLightTheme: #e6e6e6;
$iconHighlightColor: #d8bd14;
$iconHoverColorDarkTheme: rgba(255, 255, 255, 0.1);
$iconHoverColorLightTheme: rgba(0, 0, 0, 0.1);
$backgroundHoverColor: #313131;
// #808080
