.layout {
  display: flex;
  justify-content: center;
  position: relative;

  .sidebar {
    overflow: auto;
    height: 100vh;
    position: fixed;
    z-index: 2;
    padding-bottom: 50px;

    top: 50px;
    left: 0;
    bottom: 0;
  }

  .content {
    margin-top: 20px;
  }

  .spacer {
    width: 20%;
  }

  .rightSpacer {
    width: 25%;
  }

  .collapsed-spacer {
    width: 10%;
  }

  @media screen and (max-width: 992px) {
    .collapsed-spacer,
    .spacer,
    .rightSpacer {
      display: none;
    }
  }
}
