@import '~antd/dist/antd.less';
@import './Style/customAntdComponent.less';
@import '~antd/dist/antd.dark.less';
@import '@toast-ui/editor/dist/toastui-editor.css';
@import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.toastui-editor-contents {
  z-index: auto;
  font-size: 1.4rem;
  line-height: 1.75;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.toastui-editor-contents :not(table) {
  line-height: 1.75;
}

:root {
  --body-background: @body-background;
  --primary-color: @primary-color;
  --body-background-dark-theme: #141414;
  --border-color-base: @border-color-base;
  --chat-box-background-color: #242526;
  --border-radius-base: @border-radius-base;
  --focus-conversation-color: #222e3c;
  --overlay-background-color: rgba(0, 0, 0, 0.5);
  --contrast-background-color: black;
  --google-logo-color: #ea4335;
  --facebook-logo-color: #1877f2;
  --secondary-text-color: grey;
  --search-result-background-color: rgb(36, 37, 39);
}

html {
  font-size: 11px;
  line-height: 1.75;
}

.gifInputContainer {
  display: flex;
  align-items: center;
  z-index: 1;
}

.ant-upload-list-item-name {
  flex-shrink: 1 !important;
}

.overlay-background {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: var(--overlay-background-color);
  width: 100%;
  height: 100%;
  display: none;
}

@media screen and (max-width: 992px) {
  .overlay-background {
    display: block;
  }
}

.entry-spinner {
  height: 100vh !important;
}

.spinner {
  font-size: 5rem !important;
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.more-action-box-container {
  background-color: var(--body-background-dark-theme);
  border-radius: var(--border-radius-base);
  display: flex;
  flex-direction: column;
}

.more-action-box-container > button {
  text-align: left;
}

.ant-list-item-extra {
  margin: 0 !important;
}

@media screen and (max-width: 768px) {
  .ant-card-body {
    padding: 10px;
  }
}

.ant-typography {
  white-space: pre-wrap;
  line-height: 2.5rem;
}

@btn-font-weight: 600;@border-radius-base: 5px;@popover-background: #141414;@menu-bg: transparent;@menu-inline-submenu-bg: transparent;@menu-item-color: #fff;@menu-item-active-bg: #313131;@menu-item-font-size: 1.4rem;@menu-inline-toplevel-item-height: 35px;@menu-item-height: 35px;@menu-item-boundary-margin: 0;@layout-body-background: transparent;