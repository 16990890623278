.chatConversationsListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.chatConversation {
  cursor: pointer;
}

.affixAddConversationButton {
  display: none;
}

.affixAddConversationButton {
  position: fixed;
  left: 15px;
  z-index: 10;
  bottom: 20px;
  display: none;
}

@media screen and (max-width: 992px) {
  .affixAddConversationButton {
    display: block;
  }
}
