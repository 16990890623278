.iconContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 1.3rem;
}

.shareButton {
  text-align: left;
  width: 100%;
}
